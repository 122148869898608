import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Shirky's Law`}</h1>
    <ul>
      <li parentName="ul">{`See: `}<a parentName="li" {...{
          "href": "http://michaelnielsen.org/blog/shirkys-law-and-why-most-social-software-fails/"
        }}>{`http://michaelnielsen.org/blog/shirkys-law-and-why-most-social-software-fails/`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      